import React from "react"
import Timeline from "../components/timeline/Timeline"
import Layout from "../components/layout"
import SEO from "../components/seo"

export const timeline = () => {
  return (
    <Layout>
      <SEO
        title="timeline"
        description="Have a look at what I've done over this years"
        keywords={[`timeline`, `experience`, `Jia Jiun Ku`]}
      />
      <div>
        <h1>Timeline</h1>
        <p>Timeline built with React and css</p>
        <Timeline />
      </div>
    </Layout>
  )
}

export default timeline
